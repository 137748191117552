import React from 'react'
import Image from 'next/image'

import classes from './Image.module.scss'

type TImage = {
  images: any[]
  promoted?: boolean
  wide?: boolean
}

const defaultImage = {
  width: 358,
  height: 167,
  src: '/redesign/assets/images/cards/activity/default-image.png',
  alt: 'Happy dancing parents draw',
}

const getSrc = (src) => {
  const srcBase = `${process.env.NEXT_PUBLIC_PHOTO_STORAGE}/`

  if (src.includes('://')) {
    return src
  } else {
    return srcBase + src
  }
}

export default function ImageComponent({ images, promoted, wide }: TImage) {
  let rootClasses = classes.root
  const imageData = {
    ...defaultImage,
  }

  if (wide) {
    rootClasses += ` ${classes.root_wide}`
  }

  if (images && images.length > 0) {
    const image = images[0]
    imageData.src = getSrc(image.src)
    imageData.alt = image.alt
  }

  if (promoted && images.length >= 3) {
    rootClasses += ` ${classes.root_grid}`
    const height = 166

    const thumbnailSecondaryParams = {
      // width: 123,
      width: 135,
      height: 81,
    }

    const photos = {
      main: {
        width: 229,
        height,
        src: getSrc(images[0].src),
        alt: images[0].alt || '',
      },
      thumbnail: {
        width: 135,
        height,
        src: getSrc(images[1].src),
        alt: images[1].alt || '',
      },
      thumbnails: {
        one: {
          ...thumbnailSecondaryParams,
          src: getSrc(images[2].src),
          alt: images[1].alt || '',
        },
        two: {
          ...thumbnailSecondaryParams,
          src: getSrc(images[3].src),
          alt: images[3].alt || '',
        },
      },
    }

    return (
      <div className={rootClasses}>
        <div className={classes.main}>
          <Image src={photos.main.src} alt={photos.main.alt || ''} fill={true} />
        </div>
        <div className={classes.thumbnail}>
          <Image
            src={photos.thumbnail.src}
            alt={photos.thumbnail.alt || ''}
            width={photos.thumbnail.width}
            height={photos.thumbnail.height}
          />
        </div>
        <div className={classes.thumbnails}>
          <Image
            src={photos.thumbnails.one.src}
            alt={photos.thumbnails.one.alt || ''}
            width={photos.thumbnails.one.width}
            height={photos.thumbnails.one.height}
          />
          <Image
            src={photos.thumbnails.two.src}
            alt={photos.thumbnails.two.alt || ''}
            width={photos.thumbnails.two.width}
            height={photos.thumbnails.two.height}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={rootClasses}>
      <div className={classes.main}>
        <Image src={imageData.src} alt={imageData.alt || ''} fill={true} />
      </div>
    </div>
  )
}
