'use client'

import React, { useRef, useEffect, useState, useContext } from 'react'
import { useFilters } from '@/context/FiltersContext'
import { ViewportContext } from '@/context/ViewportContext'
import { useAuth } from '@/context/AuthContext'

import UserMenu from '@/components/Layout/components/Header/components/UserMenu/UserMenu'
import ButtonAdd from '@/components/ui/ButtonAdd/ButtonAdd'
import InfoTooltip from '@/components/ui/InfoTooltip/InfoTooltip'
import AddKidModal from '@/components/AddKidModal/AddKidModal'
import Search from '@/components/Search/Search'
import Kid from '@/components/FixedHeader/components/Kid/Kid'

import useElementPosition from '@/hooks/useElementPosition'
import useKids from '@/hooks/useKids'
import useModal from '@hooks/useModal'
import useViewport from '@/hooks/useViewport'

import { calculateAge } from '@helpers/calculateAge'

import IconArrows from '@/assets/icons/buttons/double-chevron-down.svg'

import classes from './FixedHeader.module.scss'
import trackGAEvent from '@helpers/trackEvent/trackGAEvent'

const helperText =
  'You can add your children and their interests using the feature and instantly filter results to show child specific activities, events and articles.  You can use a nick name instead of a real name for your child.'

const FixedHeader = () => {
  const { filters, setFilters, updateUrl } = useFilters()

  const { isMobile } = useContext(ViewportContext)
  const viewport = useViewport({ isMobile })

  const { user, setIsOpen: showAuth } = useAuth()
  const { kids, refetch: refetchKids } = useKids(user?.id || null)

  const headerRef = useRef<HTMLDivElement>(null)

  const { isOpen: isKidsModalOpen, toggle: toggleKidsModal } = useModal()
  const { isOnTop: isFixed } = useElementPosition(headerRef)
  const [isTooltipOpen, setTooltipOpen] = useState(false)
  const [pendingLogin, setPendingLogin] = useState(false)
  const [isSearchFocused, setSearchFocused] = useState(false)
  const [showKids, setShowKids] = useState(false)

  const handleTooltipClose = () => setTooltipOpen(false)
  const handleTooltipOpen = () => setTooltipOpen(true)

  let rootClasses = classes.root

  if (isFixed) {
    rootClasses += ` ${classes.root_fixed}`
  }

  const handleAddKid = () => {
    trackGAEvent({
      action: 'click',
      category: 'Add Your Child',
      label: 'Fixed Header',
      data: `page_url: ${window.location.href}`,
    })

    if (!user?.id) {
      showAuth(true)
      setPendingLogin(true)
    } else {
      toggleKidsModal()
    }
  }

  useEffect(() => {
    if (user?.id && pendingLogin) {
      toggleKidsModal()
      setPendingLogin(false)
    }
  }, [user?.id, pendingLogin, toggleKidsModal])

  const handleShowKids = () => {
    setShowKids(!showKids)
  }

  const handleCloseKids = async (refetch) => {
    if (refetch) {
      await refetchKids()
    }
    toggleKidsModal()
  }

  useEffect(() => {
    if (!isFixed) {
      setSearchFocused(false)
      setShowKids(false)
    }
  }, [isFixed])

  let showKidsControls = !isSearchFocused
  let showUserMenu = isFixed && !isSearchFocused
  let showSearch = isFixed
  let showTooltip = !user?.id && !isFixed

  if (viewport !== 'mobile') {
    showKidsControls = true
    showUserMenu = true
    showSearch = true
    showTooltip = true
  }

  const ShowKids = ({ isActive, collapsed }: { isActive?: boolean; collapsed?: boolean }) => (
    <ButtonAdd
      onClick={handleShowKids}
      text="My Kids"
      Icon={IconArrows}
      theme={isFixed ? 'white' : undefined}
      collapsed={collapsed}
      isActive={isActive}
    />
  )

  const handleKidSet = ({ kid, checked }) => {
    setFilters((prevFilters) => {
      const normalizedActiveKids = prevFilters.activeKids.map((k) =>
        typeof k.uid !== 'undefined' && !k.birthdate && !k.interests && kids
          ? kids.find((kid) => kid.uid === k.uid)
          : k,
      )

      const newActiveKids = checked
        ? [...normalizedActiveKids, kid]
        : normalizedActiveKids.filter((k) => k?.uid !== kid.uid)

      const newAges = newActiveKids.map((k) => calculateAge(k.birthdate))
      const newExperiences = [...new Set(newActiveKids.flatMap((k) => k.interests))]

      const updatedFilters = {
        ...prevFilters,
        ages: newAges,
        experiences: newExperiences,
        activeKids: newActiveKids,
      }

      updateUrl({
        ...updatedFilters,
      })

      return updatedFilters
    })
  }

  return (
    <>
      <div className={rootClasses} ref={headerRef}>
        <div className="container">
          <div className={classes.row}>
            {showKidsControls && (
              <div className={classes.kids}>
                {!kids || kids?.length === 0 || showKids ? (
                  <ButtonAdd
                    onClick={handleAddKid}
                    text="Add your child"
                    collapsed={viewport === 'mobile' && isFixed}
                    theme={isFixed ? 'white' : undefined}
                  />
                ) : (
                  <ShowKids />
                )}
                {showTooltip && (
                  <div style={{ width: 20, height: 20 }}>
                    <InfoTooltip
                      title={helperText}
                      onClose={handleTooltipClose}
                      open={isTooltipOpen}
                      onOpen={handleTooltipOpen}
                      iconColor={isFixed ? '#AACFFF' : undefined}
                    />
                  </div>
                )}
              </div>
            )}
            <div className={classes.search}>
              <Search
                collapsed={!showSearch}
                isOpen={isSearchFocused}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                onOpen={() => setSearchFocused(true)}
                onClose={() => setSearchFocused(false)}
              />
            </div>
            {showUserMenu && (
              <div className={classes.user}>
                <UserMenu
                  styles={isFixed ? { borderColor: '#FFFFFF' } : {}}
                  iconColor={isFixed ? '#FFFFFF' : undefined}
                />
              </div>
            )}
          </div>
          {showKids && (
            <div className={classes.row}>
              <ShowKids isActive={true} collapsed={true} />
              <div className={classes.filters}>
                {kids &&
                  kids.length &&
                  kids.map((kid, i) => {
                    const isChecked = filters.activeKids.find((k) => k.uid === kid.uid)
                    return (
                      <Kid key={i} {...kid} onChange={handleKidSet} checked={Boolean(isChecked)} />
                    )
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      <AddKidModal isOpen={isKidsModalOpen} onClose={handleCloseKids} />
    </>
  )
}

export default FixedHeader
