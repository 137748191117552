import { useState, useEffect, MutableRefObject } from 'react'

const useElementPosition = (ref: MutableRefObject<HTMLDivElement | null>) => {
  const [isOnTop, setIsOnTop] = useState(false)

  const handleScroll = () => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top
      setIsOnTop(top <= 0)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return { isOnTop }
}

export default useElementPosition
